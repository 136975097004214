import React from 'react'

export default () => {
	return (
		<section style={{fontFamily: 'Arial'}}>
			<h1>No Content Found</h1>
			<p>
				Please double check your Environment settings or your .env files.
			</p>
			<p>
				Need some help?  Check out the <a href="https://help.agilitycms.com/hc/en-us/articles/360039879872">Agility CMS - Gatsby docs.</a>
			</p>
		</section>
	)
}